(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

$(document).foundation();

window.onload = function () {
  stickyFooter();
  $('.menu.vertical.accordion-menu[data-accordion-menu] .is-submenu-item.is-accordion-submenu-item > a.current_sub').parents().find('.is-active.is-accordion-submenu-parent > .submenu-toggle ').trigger('click');
};

function checkForDOMChange() {
  stickyFooter();
}

window.onresize = function () {
  stickyFooter();
};

function getCSS(element, property) {
  var elem = document.getElementsByTagName(element)[0];
  var css = null;

  if (elem.currentStyle) {
    css = elem.currentStyle[property];
  } else if (window.getComputedStyle) {
    css = document.defaultView.getComputedStyle(elem, null).getPropertyValue(property);
  }

  return css;
}

function stickyFooter() {
  if (document.getElementsByTagName("footer")[0].getAttribute("style") != null) {
    document.getElementsByTagName("footer")[0].removeAttribute("style");
  }

  if (window.innerHeight != document.body.offsetHeight) {
    var offset = window.innerHeight - document.body.offsetHeight;
    var current = getCSS("footer", "margin-top");

    if (isNaN(current) == true) {
      document.getElementsByTagName("footer")[0].setAttribute("style", "margin-top:0px;");
      current = 0;
    } else {
      current = parseInt(current);
    }

    if (current + offset > parseInt(getCSS("footer", "margin-top"))) {
      document.getElementsByTagName("footer")[0].setAttribute("style", "margin-top:" + (current + offset) + "px;");
    }
  }
}

$(window).on('load', function () {
  var sync1 = $("#owl-gallery");
  var sync2 = $("#owl-thumbs");
  var slidesPerPage = 4; //globaly define number of elements per page

  var syncedSecondary = true;
  sync1.owlCarousel({
    items: 3,
    slideSpeed: 2000,
    nav: true,
    autoplay: false,
    dots: false,
    center: true,
    loop: true,
    responsiveRefreshRate: 100,
    autoHeight: false,
    autoWidth: true,
    navText: ["", ""]
  }).on('changed.owl.carousel', syncPosition);
  sync2.on('initialized.owl.carousel', function () {
    sync2.find(".owl-item").eq(0).addClass("current");
  }).owlCarousel({
    items: slidesPerPage,
    dots: false,
    nav: false,
    smartSpeed: 200,
    slideSpeed: 500,
    autoHeight: false,
    autoWidth: true,
    slideBy: slidesPerPage,
    //alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
    responsiveRefreshRate: 100
  }).on('changed.owl.carousel', syncPosition2);

  function syncPosition(el) {
    //if you set loop to false, you have to restore this next line
    //var current = el.item.index;
    //if you disable loop you have to comment this block
    var count = el.item.count - 1;
    var current = Math.round(el.item.index - el.item.count / 2 - .5);

    if (current < 0) {
      current = count;
    }

    if (current > count) {
      current = 0;
    } //end block


    sync2.find(".owl-item").removeClass("current").eq(current).addClass("current");
    var onscreen = sync2.find('.owl-item.active').length - 1;
    var start = sync2.find('.owl-item.active').first().index();
    var end = sync2.find('.owl-item.active').last().index();

    if (current > end) {
      sync2.data('owl.carousel').to(current, 100, true);
    }

    if (current < start) {
      sync2.data('owl.carousel').to(current - onscreen, 100, true);
    }
  }

  function syncPosition2(el) {
    if (syncedSecondary) {
      var number = el.item.index;
      sync1.data('owl.carousel').to(number, 100, true);
    }
  }

  sync2.on("click", ".owl-item", function (e) {
    e.preventDefault();
    var number = $(this).index();
    sync1.data('owl.carousel').to(number, 300, true);
  });
});
/*
*
* --- --- --- --- --- --- --- --- --- --- Externe Links in neuem Fenster öffnen
*
*/

$('a.extern').click(function () {
  window.open($(this).attr('href'));
  return false;
});

},{}]},{},[1]);
